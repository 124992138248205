@import "rsuite/dist/rsuite.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import "rsuite/dist/rsuite.css";

@tailwind base;

@tailwind components;

@tailwind utilities;

* {
  @apply font-open-sans;
}
body {
  @apply scroll-smooth antialiased;
}
h1 {
  @apply !leading-[initial];
}

/* component classes start */
.hello-test-class {
  color: red;
}
.secondary-btn {
  @apply min-w-[120px] border rounded-full bg-secondary-blue hover:bg-white hover:text-secondary-blue border-secondary-blue px-5 py-2 font-bold   text-white transition-all ease-in-out duration-500;
}
.last-update-btn {
  @apply border w-full rounded-full bg-secondary-blue p-2  font-bold  text-white hover:bg-white hover:text-secondary-blue border-secondary-blue transition-all ease-in-out duration-500;
}
.activity-btn {
  @apply border rounded-full  w-full py-2 font-bold  bg-secondary-blue text-white cursor-default;
}
.info-btn {
  @apply text-sm font-extrabold bg-green-600 hover:bg-white hover:text-green-600 px-2 rounded-full text-white border border-green-600 transition-all ease-in-out duration-500;
}

.green-btn {
  @apply bg-green-600 px-5 py-[10px] text-white font-bold rounded border hover:bg-white hover:text-green-600 border-green-600 transition-all ease-in-out duration-500;
}

.calendar-yellow-button {
  @apply w-11 h-12 absolute bottom-9 border right-10 cursor-pointer bg-yellow-500  border-yellow-500 rounded-lg hover:bg-white hover:text-yellow-500;
}

.avatar-grade {
  @apply text-sm cursor-default px-3  py-[6px] font-extrabold   rounded-full text-white border;
}
.info-left-tooltip {
  @apply hidden group-hover/info-tooltip:block absolute -top-[14px] -left-[205px] w-[200px] rounded h-[43px] bg-slate-800 py-1 leading-[17px] px-3 text-white font-semibold text-sm;
}

/* component classes end */

.main-content-container {
  min-height: calc(100vh - 186px);
}
.sub-nav-item {
  @apply md:rounded-t-primary
   bg-white   cursor-pointer text-[14px]
    leading-[19px] font-[600] hover:text-white
     hover:bg-primary-blue active:bg-primary-blue
      active:text-white transition-all duration-300 ease-in-out flex justify-between md:flex;
}
.left-sub-nav-item {
  @apply text-white focus:bg-white focus:text-primary-blue active:bg-white active:text-primary-blue;
}
.left-sub-nav-item a {
  @apply text-white active:text-white  rounded-t-primary focus:text-white bg-slate-400 hover:bg-primary-blue hover:text-white focus:bg-primary-blue active:bg-primary-blue;
}
.sub-nav-item.right-sub-nav-item:last-child ul.sub-menu-list {
  @apply right-[11px];
}

.sub-nav-item > a {
  @apply text-primary-grey;
}
.left-sub-nav-item > a {
  @apply text-white;
}

.sub-nav-item:hover > a {
  @apply md:text-white;
}

.menu-sub-menu-items {
  @apply px-[5px] md:px-[4px] lg:px-[8px]  2xl:px-7  py-2 hover:text-primary-blue  text-black transition-all duration-300 ease-in-out flex justify-between gap-1 lg:gap-5;
}

.profile-tabs a {
  @apply text-black !text-[16px] !leading-[24px];
}
.profile-tabs a:hover {
  @apply !text-white !no-underline;
}
.profile-tab-item:hover .profile-tab-item a {
  @apply text-white;
}
a:active,
a:focus,
a:hover {
  @apply !no-underline;
}
ol,
ul {
  @apply !mb-0 !mt-0;
}
p + p {
  @apply !mt-0;
}

.table-container {
  @apply w-full overflow-auto;
}

.label-text {
  @apply text-xs font-bold text-slate-700;
}

.width-299 {
  @apply w-[299px];
}
/* OVERRIDING CSS OF RSUITE */

.rs-picker-toolbar-right > button {
  @apply bg-rs-blue-btn;
}

.rs-picker-check-menu {
  @apply order-3;
}

.dashboard-accordion-panel .rs-panel-header,
.dashboard-accordion-panel .rs-panel-body {
  @apply p-0;
}
.rs-btn-primary {
  @apply !border !border-rs-blue-btn !border-solid;
}
.rs-btn-primary.primary-custom-green-btn {
  @apply !border-green-600 hover:!text-green-600;
}
.rs-btn-primary.trackingdata-btn-yellow {
  @apply !border !border-yellow-500;
}
.rs-btn-primary.trackingdata-btn-green {
  @apply !border !border-green-500;
}
.rs-btn-primary.trackingdata-btn-red {
  @apply !border !border-red-500;
}
.rs-btn.rs-btn-default {
  @apply !border !border-primary-background-color !bg-primary-background-color !border-solid !text-slate-600 transition-all ease-in-out duration-300;
}
.rs-btn.rs-btn-default:hover {
  @apply !bg-white !border-slate-600;
}
.userManagement .rs-picker-toggle-wrapper {
  @apply !w-[300px];
}
.icon-btn-status.rs-btn-primary,
.popover-icon.rs-btn-primary {
  @apply !border-0;
}

/* ====== accordion styles ===== */

input.accordion-checkbox:checked ~ .accordion-data {
  @apply max-h-screen;
}
.activity-1 {
  @apply bg-[#007FCB] hover:border-[#007FCB] border-[#007FCB];
}

.activity-2 {
  @apply bg-[#02A574] hover:border-[#02A574] border-[#02A574] hover:text-[#02A574];
}
.activity-3 {
  @apply bg-[#F76232] hover:border-[#F76232] border-[#F76232] hover:text-[#F76232];
}

.activity-4 {
  @apply bg-[#FFCA43] hover:border-[#FFCA43] border-[#FFCA43] hover:text-[#FFCA43];
}
.activity-5 {
  @apply bg-cyan-400  border-cyan-400 hover:text-cyan-400;
}
.assign-delegate-button {
  @apply !ml-0 md:ml-5;
}
.error-message .rs-form-error-message {
  @apply !bg-transparent !border-none !py-0 !pl-0 !pr-[10px];
}
.error-message .rs-form-error-message-arrow::before {
  @apply hidden;
}
.error-message .rs-form-error-message-arrow::after {
  @apply hidden;
}

/* RHF Multicheck style */

.rs-picker span.rs-picker-value-count {
  @apply bg-primary-grey;
}

.rs-picker span.rs-picker-value-list,
.rs-picker .rs-picker-toggle-value {
  @apply !text-primary-grey;
}
textarea {
  @apply resize-none;
}

/* RHF Multicheck style */

@media screen and (max-width: 766px) {
  .data-content {
    @apply block whitespace-nowrap p-0 border-none;
  }
  .data-content > div {
    @apply inline-block p-1 border-b border-gray-200 w-[150px] text-ellipsis whitespace-nowrap overflow-hidden;
  }
  .rs-btn-primary {
    height: 43px !important;
    align-self: center !important;
  }
  .delegate-search-box {
    margin: 0px !important;
    height: 43px !important;
    align-self: center !important;
  }
}
/** dashboard avatar health images*/
.badge--avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #939491;
  color: #fff;
  margin: 0 5px;
  display: inline-block;
  line-height: 32px;
  text-align: center;
  font-size: 12px;
}
.health--A .badge--avatar {
  background-color: #3fb562;
  background-image: url("./../../public/images/icon1.png");
  background-size: cover;
}
.health--B .badge--avatar {
  background-color: #0e9da5;
  background-image: url("./../../public/images/icon2.png");
  background-size: cover;
}
.health--C .badge--avatar {
  background-color: #a97fab;
  background-image: url("./../../public/images/icon3.png");
  background-size: cover;
}
.health--D .badge--avatar {
  background-color: #ee8e47;
  background-image: url("./../../public/images/icon4.png");
  background-size: cover;
}
.health--E .badge--avatar {
  background-color: #ee8e47;
  background-image: url("./../../public/images/icon5.png");
  background-size: cover;
}
.health--F .badge--avatar {
  background-color: #e4403e;
  background-image: url("./../../public/images/icon5.png");
  background-size: cover;
}
.rs-form-vertical .rs-form-group .rs-input-group {
  width: auto !important;
}
.call-maximizer-class select {
  min-width: 75px;
  text-align: center;
}

.search-table-wrapper tbody tr:nth-child(even) select {
  @apply bg-gray-100;
}

tr.callMaximizer__row-class > td.call-maximizer-class:nth-child(4) {
  @apply !text-center;
}

.callMaximizer__thead-class > th:nth-child(4) {
  @apply !text-center;
}

/* .apexcharts-series:hover ~ .apexcharts-legend-series {
  display: none;
}
.apexcharts-canvas svg .apexcharts-datalabels-group{
  display: none;
} */
.apexcharts-datalabels-group {
  display: none;
}
.apexcharts-legend {
  height: fit-content !important;
  padding: 0px !important;
}
.modal-content-drawer .rs-form-control {
  width: 100%;
}

.rs-drawer-open .rs-drawer {
  @apply !overflow-scroll !bg-white;
}

@media screen and (max-width: 991px) {
  .modal-content-drawer {
    width: 100% !important;
  }
}
/* calender style */

.rs-picker-daterange-predefined.rs-stack .rs-stack-item .rs-btn:hover {
  background: #007fcb;
  text-decoration: none;
  color: white;
}

.single-user-wrapper::after {
  position: relative;
}
.single-user-wrapper::after {
  content: "";
  background-color: #e2e8f0;
  width: 100%;
  height: 30px;
  position: absolute;
  display: block;
  z-index: 100;
  top: -30px;
  left: 0;
}

.single-user-wrapper {
  position: relative;
}
.single-user-wrapper::after {
  content: "";
  background-color: #e2e8f0;
  width: 100%;
  height: 30px;
  position: absolute;
  display: block;
  z-index: 100;
  top: -30px;
  left: 0;
}

.single-user-wrapper::after {
  content: "";
  background-color: #e2e8f0;
  width: 100%;
  height: 30px;
  position: absolute;
  display: block;
  z-index: 100;
  top: -30px;
  left: 0;
}
.single-user-wrapper #panel1 {
  height: 0px;
}

.userManagement .rs-form-group:not(:last-child) {
  margin-bottom: 0px !important;
}

.Vmap-definition form .rs-form-control {
  width: 100%;
}
/* calender styles */
span.rs-picker-toggle-label {
  @apply bg-rs-blue-btn px-2 py-[2px] border-transparent border rounded-[14px] text-white mr-[4px] font-semibold transition-all ease-in-out duration-500;
}
span.rs-picker-toggle-label:hover {
  @apply border-rs-blue-btn bg-white text-rs-blue-btn;
}
.call-maximizer-content .pair-item {
  @apply lg:!w-full;
}
.rs-picker-menu {
  /* @apply !z-[150]; */
}
.rs-modal-open .rs-picker-menu {
  @apply !z-[1055];
}
.rs-modal-open .rs-picker-menu {
  z-index: 1055 !important;
}
.rs-btn .rs-btn-primary {
  @apply !border-rs-blue-btn;
}
.rs-btn-primary:hover {
  @apply !bg-white !text-rs-blue-btn;
}

.rs-panel-title:hover {
  color: #3c8dbc !important;
}
/* .rs-picker-daterange-menu {
  @apply   !h-[400px] lg:!h-[300px] 2xl:!h-[500px] !overflow-auto;
} */

/* ===== modal box styles ===== */

.rs-drawer-open .rs-drawer {
  @apply !w-full lg:!w-[968px];
}
.rs-drawer-content .grid-cols-2 {
  @apply !grid-cols-1 md:!grid-cols-2;
}
/* =====breadcrumb styles===== */

.card .rs-breadcrumb a.rs-breadcrumb-item:last-child {
  @apply border border-yellow-300 bg-yellow-300 hover:bg-white  px-1 py-[2px]  rounded font-medium;
}

.card.home-icon nav.rs-breadcrumb {
  @apply pl-4;
}
/* =========coach path styles ========== */
.coach-path > .rs-panel-header {
  @apply bg-secondary-grey font-semibold pl-7;
}
.coach-path > .rs-panel-collapse {
  @apply pb-5 bg-secondary-grey;
}
.coach-path > .rs-panel-collapse > .rs-panel-body {
  @apply bg-secondary-grey;
}
.coach-path-header-title > .rs-panel-collapse {
  @apply mx-[10px] md:mx-[19px] my-0 bg-white;
}

.coach-path-header-title > .rs-panel-header {
  @apply bg-white mt-0 ml-[10px] md:ml-[19px] mb-[5px] mr-[10px] md:mr-[19px] font-semibold pl-7;
}
.coach-path-secondary-heading .rs-panel-header {
  @apply text-primary-blue;
}
.coach-path-secondary-heading .rs-panel-header svg {
  @apply fill-primary-blue;
}
.coach-path.rs-panel-collapsible > .rs-panel-header > .rs-icon {
  @apply left-[2px] top-[22px] -rotate-90 ml-1;
}
.coach-path-header-title.rs-panel-collapsible > .rs-panel-header > .rs-icon,
.coach-path-module.rs-panel-collapsible > .rs-panel-header > .rs-icon {
  @apply left-[2px] top-[22px] -rotate-90 ml-1 fill-secondary-blue mr-1;
}
.coach-path-header-title > .rs-panel-body {
  padding-left: 5px;
  padding-right: 5px;
}

.coach-path-secondary-heading.rs-panel-collapsible
  > .rs-panel-header
  > .rs-icon {
  @apply left-[2px] top-[22px] -rotate-90;
}

.coach-path-module {
  @apply bg-white mt-5 ml-[10px] md:ml-[19px] mb-[5px] mr-[10px] md:mr-[19px];
}
.coach-path-module > .rs-panel-header {
  @apply font-semibold pl-7 border-b-[6px] border-secondary-grey;
}

/* =====coach path checklist===== */
.coach-path-checklist .rs-panel-collapse .rs-panel-body {
  @apply !pt-5 !pl-8;
}

/* =========coach path styles ========== */
.coach-path > .rs-panel-header {
  @apply bg-secondary-grey font-semibold pl-7;
}
.coach-path > .rs-panel-collapse {
  @apply pb-5 bg-secondary-grey;
}
.coach-path > .rs-panel-collapse > .rs-panel-body {
  @apply bg-secondary-grey;
}
.coach-path-header-title > .rs-panel-collapse {
  @apply mx-[19px] my-0 bg-white;
}

.coach-path-header-title > .rs-panel-header {
  @apply bg-white mt-0 ml-[19px] mb-[5px] mr-[19px] font-semibold pl-7;
}
.coach-path-secondary-heading .rs-panel-header {
  @apply text-primary-blue;
}
.coach-path-secondary-heading .rs-panel-header svg,
.dashboard-accordion-panel.rs-panel-collapsible > .rs-panel-header > .rs-icon {
  @apply fill-primary-blue;
}
.coach-path.rs-panel-collapsible > .rs-panel-header > .rs-icon {
  @apply left-[2px] top-[22px] -rotate-90 ml-1;
}
.dashboard-accordion-panel.rs-panel-collapsible > .rs-panel-header > .rs-icon {
  @apply -left-[7px] top-[5px] -rotate-90 ml-1 text-[30px];
}
.dashboard-accordion-panel.rs-panel-collapsible.rs-panel-in
  > .rs-panel-header
  > .rs-icon {
  @apply !rotate-0;
}

.dashboard-accordion-panel .rs-panel-title {
  @apply h-[38px];
}
.coach-path-header-title.rs-panel-collapsible > .rs-panel-header > .rs-icon,
.coach-path-module.rs-panel-collapsible > .rs-panel-header > .rs-icon {
  @apply left-[2px] top-[22px] -rotate-90 ml-1 fill-secondary-blue mr-1;
}
.coach-path-secondary-heading.rs-panel-collapsible
  > .rs-panel-header
  > .rs-icon {
  @apply left-[2px] top-[22px] -rotate-90;
}
.coach-path-module {
  @apply bg-white mt-5 ml-[19px] mb-[5px] mr-[19px];
}
.coach-path-module > .rs-panel-header {
  @apply font-semibold pl-7;
}

/* =====core disciplines===== */
.core-discipline.coach-path .rs-panel-collapse {
  @apply pb-0;
}
.core-discipline.coach-path .rs-panel-body-fill {
  @apply bg-white !pl-9 !pt-4;
}

/* carousel styles */
/* .react-multi-carousel-track {
  @apply gap-5;
} */
.react-multi-carousel-list.my-carousel-container ul li {
  padding-right: 1.25rem;
}
button.react-multiple-carousel__arrow {
  @apply !bg-primary-blue !z-20 hover:!bg-white hover:!text-primary-blue !border !border-primary-blue;
  border: 1px solid;
}

button.react-multiple-carousel__arrow:hover::before {
  @apply !text-primary-blue;
}

.rs-modal-header .rs-btn-close .rs-icon,
.rs-drawer-header .rs-btn-icon.rs-btn-sm > .rs-icon {
  @apply !w-[24px] !h-[24px];
}
.rs-modal-header .rs-picker-daterange .rs-btn-close .rs-icon {
  @apply !w-[12px] !h-[12px];
}
.table-data-para textarea,
.insertdata-input-wrapper textarea {
  @apply !border !border-gray-400 !p-2;
}
.insertdata-input-wrapper textarea {
  @apply !p-2 !w-full;
}
.insertdata-input-wrapper .profile-content-tab {
  @apply !mt-0;
}
.insertdata-input-wrapper .pair-item {
  @apply !px-[5px];
}
.insertdata-input-wrapper .pair-one > .pair-item:last-child {
  @apply !w-full;
}
.edit-form-details {
  @apply flex flex-wrap items-center justify-center;
}
.edit-form-details .rs-form-group:not(:last-child) {
  @apply !w-1/2;
}
.anchor-hover-focus:hover:focus {
  @apply text-primary-blue;
}
.edit-trackingData-form div {
  max-width: 357px;
}
.edit-trackingData-form div:nth-child(4) {
  max-width: 616px;
}
.accordion-collapse > div {
  text-align: center;
}
.edit-trackingData-form .rs-form-control-wrapper,
.edit-trackingData-form .edit-trackingData-input {
  @apply !w-full !block;
}
.edit-trackingData-form .rs-form-group {
  @apply !w-full !block col-span-2 lg:col-span-1;
}
.delegate-edit-form-details .rs-form-control-wrapper,
.delegate-edit-form-details .edit-delegate-input {
  @apply !w-full !block;
}
.delegate-edit-form-details {
  @apply grid grid-cols-1 md:grid-cols-2 gap-2;
}
.delegate-edit-form-details .rs-form-group {
  @apply !w-full !block col-span-2 lg:col-span-1;
}

.custom-header .rs-drawer-header-close {
  @apply md:!left-[690px] lg:!left-[873px];
}
.custom-header .custom-title {
  @apply md:order-2;
}
.custom-header .custom-action-btn {
  @apply md:order-1;
  display: flex !important;
  gap: 10px;
}

/* ===== call-maximizer-overdue list styles===== */
.overdue-date {
  @apply bg-[#00c1f0] px-[10px] py-[5px] rounded-md text-white font-extrabold w-max mb-[10px];
}
.overdue-checkbox-wrapper {
  @apply bg-[#d9edf8] p-[5px] mr-[10px] mb-[5px] rounded flex justify-items-start gap-[10px] items-baseline cursor-pointer shadow-sm;
}

.data-highlight {
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 100%;
  border: 1px solid coral;
}

/* media query */
@media only screen and (max-width: 1640px) {
  .rs-picker-daterange-menu .rs-picker-daterange-calendar-group {
    display: flex;
    height: 557px;
    width: 248px;
    min-width: 208px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  h4 {
    font-size: 18px !important;
    line-height: 24px !important;
  }
  .left-sub-nav-item > a {
    @apply bg-transparent text-primary-grey;
  }
  .sub-nav-item {
    @apply block hover:bg-white active:bg-white text-slate-400 drop-shadow-lg;
  }
  .sub-nav-item > a {
    @apply text-primary-grey hover:text-primary-grey active:text-primary-grey;
  }
  .sub-menu-list {
    @apply flex relative w-full h-full  text-primary-grey bg-transparent p-[6px] pt-[5px] border-none rounded-none left-0 pb-[18px] transition-all duration-300 ease-in-out delay-300;
  }
  .menu-sub-menu-items {
    @apply text-white font-semibold;
  }
  .dropdown-menu-item {
    @apply mb-1 overflow-hidden bg-primary-blue;
  }
  .sub-navbar-container {
    @apply overflow-hidden bg-white;
  }
  .sub-navbar-small-screen {
    @apply invisible h-0 overflow-hidden;
  }
  .coach-path-header-title .rs-panel-collapse .rs-panel-body {
    @apply pl-0 pr-0;
  }

  .coach-path-secondary-heading .rs-panel-collapse .rs-panel-body {
    @apply pl-[5px] pr-[5px];
  }

  .coach-path-module .rs-panel-collapse .rs-panel-body {
    @apply pl-[5px] pr-[5px];
  }
}

@media only screen and (max-width: 668px) {
  .rs-picker-daterange-menu {
    left: 10px !important;
  }
  .rs-picker-daterange-menu .rs-calendar {
    border-left: 1px solid var(--rs-border-primary) !important;
    margin: 0 !important;
  }
  .rs-calendar-body {
    @apply !pl-[2px] !pr-[2px];
  }
  .rs-picker-daterange-menu .rs-picker-daterange-calendar-group {
    @apply w-[219px] h-[557px] flex flex-col min-w-[208px];
  }
}
.rs-calendar-body {
  @apply !pl-[2px] !pr-[2px];
}
.rs-picker-daterange-menu .rs-picker-daterange-calendar-group {
  @apply w-[248px] h-[557px] flex flex-col min-w-[208px];
}

/* TODO - make it specific for separator */
.rs-breadcrumb-separator {
  @apply text-base;
}

.manage-users-form-wrapper .rs-form-control-wrapper,
.manage-users-form-wrapper .rs-form-control-wrapper input {
  width: 100%;
}
.rs-drawer-open .rs-picker-menu,
.rs-modal-open .rs-picker-menu {
  z-index: 1055 !important;
}

.table-sec .colapse-item:first-child .colapse-btn::after {
  content: none;
}
.default-table-row > .default-table-data:first-child > p.table-data-para {
  @apply !p-0;
}

@media screen and (max-width: 767px) {
  .rs-drawer-dialog .rs-drawer-content .rs-drawer-body {
    padding: 20px;
  }
}

.dashboard-drawer-modal-content .modal-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

@media screen and (max-width: 991px) {
  .dashboard-drawer-modal-content .modal-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .dashboard-drawer-modal-content .modal-content {
    grid-template-columns: 1fr;
  }
  .dashboard-accordion-panel.vmap-accordion-container
    .rs-panel-header
    .rs-panel-title {
    @apply justify-center items-start;
  }
}

.ProseMirror-focused {
  outline: none;
}

.single-user-wrapper .rs-anim-collapse[aria-expanded="false"] {
  position: relative;
  display: block !important;
}
/* panel collapse filter */
.rs-anim-collapse[aria-expanded="false"] .header-profile-filter {
  position: absolute;
  top: -60px;
  left: 165px;
}
.single-user-wrapper
  .rs-anim-collapse[aria-expanded="false"]
  .rs-panel-body
  hr {
  margin: 0 !important;
}

.expend-hover-custom:hover {
  height: auto !important;
  min-height: 262px;
  transition: height 0.4s !important;
}
.ProseMirror ul,
ol,
menu {
  padding: 0 1rem;
  list-style: revert;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.coach-path-top-heading h6,
.table-title h6 {
  @apply !text-[22px];
}
.coach-path .rs-panel-header .rs-panel-title span {
  @apply !text-[20px];
}

.coach-path .coach-path-header-title .rs-panel-header .rs-panel-title span {
  @apply !text-[18px];
}
.coach-path
  .coach-path-secondary-heading
  .rs-panel-header
  .rs-panel-title
  span {
  @apply !text-[18px];
}
.coach-path .coach-path-secondary-heading .rs-panel-body h6 {
  @apply !text-[16px];
}
.coach-path .coach-path-secondary-heading .rs-panel-body ul li h6 {
  @apply !text-[14px];
}
.coach-path .coach-path-secondary-heading .rs-panel-body ul li::marker {
  @apply !text-[14px] font-bold;
}

.coach-path .coach-path-secondary-heading .rs-panel-body p strong,
.coach-path .coach-path-secondary-heading .rs-panel-body strong,
.coach-path .coach-path-secondary-heading .rs-panel-body .key-topics {
  @apply !text-[14px];
}

.horizontal-text::first-letter {
  @apply font-bold;
}
